<template>
  <v-form ref="form" v-model="stepTwoFormSuccess" class="form-grid">
    <div class="form-grid_use-row">
      <div class="d-flex justify-space-between">
        <span>
          ประเภทความเสียหาย
          <span class="red--text"> * </span>
        </span>
        <a
          class="blue--text underline-hover"
          @click="$store.dispatch('inspectionDamageDialog/openHistoryDialog')"
        >
          ประวัติการตรวจสอบ
        </a>
      </div>
      <v-autocomplete
        v-model="damageTypeId"
        :items="damageList"
        :rules="[rules.required]"
        background-color="white"
        class="mt-1"
        dense
        hide-details
        item-text="name"
        item-value="id"
        outlined
        no-data-text="ไม่มีประเภทความเสียหายให้เลือก"
        placeholder="เลือก"
        @change="damageTypeIdInputChange"
      />
    </div>
    <div class="form-grid_use-row">
      <div class="d-flex justify-space-between">
        <span>
          คะแนน
          <span class="red--text"> * </span>
        </span>
        <a
          class="blue--text underline-hover"
          @click="
            $store.commit(
              'inspectionDamageDialog/SET_SUGGESTION_DIALOG_DRAWER',
              true
            )
          "
        >
          คำแนะนำ
        </a>
      </div>
      <v-select
        v-model="scoreId"
        :items="refScoreByDamageId"
        :rules="[rules.required]"
        background-color="white"
        class="mt-1"
        dense
        hide-details
        item-text="text"
        item-value="id"
        outlined
        no-data-text="ไม่มีคะแนนให้เลือก"
        placeholder="เลือก"
      ></v-select>
    </div>
    <div class="form-grid_use-row font-weight-bold primary--text">
      ปริมาณความเสียหาย
      <div v-if="String(inspectionType) === '2'">
        <v-checkbox
          v-model="hasCritical"
          :disabled="severeProblem"
          class="d-block form-grid_use-row"
          color="error"
          dense
          hide-details
          style="margin-left: -4px"
          @change="areaCritical = ''"
        >
          <template v-slot:label>
            <span class="red--text"> พบความเสียหายระดับวิกฤต </span>
          </template>
        </v-checkbox>
      </div>
    </div>
    <template v-if="String(inspectionType) !== '2'">
      <div v-if="has_input_width">
        X (ม.)
        <span class="red--text"> * </span>
        <v-text-field
          v-model="damageWidth"
          :background-color="damageBackgroundColor"
          :disabled="damageTypeIdIsZero"
          :rules="requiredRulesForDamage"
          class="mt-1"
          dense
          hide-details
          outlined
          reverse
          type="number"
          min="0"
          @change="log_input($event, 'damageWidth')"
        />
      </div>
      <div v-if="has_input_height">
        H (ม.)
        <span class="red--text"> * </span>
        <v-text-field
          v-model="damageHeight"
          :background-color="damageBackgroundColor"
          :disabled="damageTypeIdIsZero"
          :rules="requiredRulesForDamage"
          class="mt-1"
          dense
          hide-details
          outlined
          reverse
          type="number"
          min="0"
          @change="log_input($event, 'damageHeight')"
        />
      </div>
      <div v-if="has_input_length">
        Y (ม.)
        <span class="red--text"> * </span>
        <v-text-field
          v-model="damageLength"
          :background-color="damageBackgroundColor"
          :disabled="damageTypeIdIsZero"
          :rules="requiredRulesForDamage"
          class="mt-1"
          dense
          hide-details
          outlined
          reverse
          type="number"
          min="0"
          @change="log_input($event, 'damageLength')"
        />
      </div>
      <div v-if="has_input_thickness">
        B (ม.)
        <span class="red--text"> * </span>
        <v-text-field
          v-model="damageThickness"
          :background-color="damageBackgroundColor"
          :disabled="damageTypeIdIsZero"
          :rules="requiredRulesForDamage"
          class="mt-1"
          dense
          hide-details
          outlined
          reverse
          type="number"
          min="0"
          @change="log_input($event, 'damageThickness')"
        />
      </div>
      <div v-if="has_input_piece">
        ea.
        <span class="red--text"> * </span>
        <v-text-field
          v-model="damagePiece"
          :background-color="damageBackgroundColor"
          :disabled="damageTypeIdIsZero"
          :rules="requiredRulesForDamage"
          class="mt-1"
          dense
          hide-details
          outlined
          reverse
          type="number"
          min="0"
          @change="log_input($event, 'damagePiece')"
        />
      </div>
      <div>
        จุดอ้างอิง (ม.)
        <span class="red--text"> * </span>
        <v-text-field
          v-model="pointReference"
          :background-color="damageBackgroundColor"
          :disabled="damageTypeIdIsZero"
          :rules="
            damageTypeIdIsZero
              ? []
              : [rules.requiredAllowZero, rules.onlyPositive]
          "
          class="mt-1"
          dense
          hide-details
          outlined
          reverse
          type="number"
          min="0"
          @change="log_input($event, 'pointReference')"
        />
      </div>
    </template>
    <template v-if="String(inspectionType) === '2'">
      <div>
        ปริมาณความเสียหาย
        <span v-if="hasCritical" class="red--text"> * </span>
        <v-text-field
          v-model="areaCritical"
          :background-color="
            disableAreaCritical || damageTypeIdIsZero
              ? 'grey lighten-3'
              : 'white'
          "
          :disabled="disableAreaCritical || damageTypeIdIsZero"
          :rules="hasCritical ? requiredRulesForDamage : []"
          class="mt-1"
          dense
          hide-details
          outlined
          reverse
          type="number"
          min="0"
          @change="log_input($event, 'areaCritical')"
        />
      </div>
      <div>
        หน่วย
        <span v-if="hasCritical" class="red--text"> * </span>
        <v-select
          v-model="principalUnit"
          :background-color="
            disableAreaCritical || damageTypeIdIsZero
              ? 'grey lighten-3'
              : 'white'
          "
          :disabled="disableAreaCritical || damageTypeIdIsZero"
          :rules="hasCritical ? requiredRulesForDamage : []"
          :items="units"
          class="mt-1"
          dense
          hide-details
          item-value="id"
          item-text="name"
          outlined
        ></v-select>
      </div>
    </template>
    <div class="form-grid_use-row">
      หมายเหตุ (รายละเอียดความเสียหาย)
      <v-textarea
        v-model="remarks"
        background-color="white"
        class="mt-1"
        dense
        hide-details
        outlined
        rows="3"
      />
    </div>
  </v-form>
</template>
<script>
import { mapGetters, mapState } from 'vuex'

export default {
  name: 'step-two-damage-area',
  data: () => ({
    rules: {
      required: (value) => !!value || 'ต้องการ',
      requiredAllowZero: (value) =>
        parseInt(value) === 0 || !!value || 'ต้องการ',
      onlyPositive: (value) =>
        (!isNaN(value) && parseFloat(value) >= 0) || 'ต้องการค่า > 0'
    }
  }),
  methods: {
    damageTypeIdInputChange (e) {
      this.$nextTick(() => {
        // wait computed parameter
        if (this.refScoreByDamageId.length === 1) {
          this.scoreId = this.refScoreByDamageId[0].id
        }
      })
      if (this.damageTypeId === '0') {
        this.areaCritical = ''
        this.damageWidth = ''
        this.damageHeight = ''
        this.damagePiece = ''
        this.pointReference = '0'
      } else {
        this.scoreId = null
      }
    },
    toPositive (value, allowZero = false) {
      const n = Number(value)
      if (parseFloat(n) === 0 || value === '') {
        if (allowZero) {
          return 0
        }
        return ''
      } else {
        return n <= 0 ? -1 * n : n
      }
    },
    log_input (e, keyName) {
      this.$nextTick(() => {
        const allowZero = keyName === 'pointReference'
        const newVal = this.toPositive(e, allowZero)
        if (newVal === null) {
          this[keyName] = 9999
          this.$nextTick(() => {
            this[keyName] = null
          })
        } else {
          this[keyName] = newVal
        }
      })
    }
  },
  computed: {
    ...mapState('inspectionDamageDialog', {
      inspectionType: 'inspectionType',
      damageArray: 'damageArray'
    }),
    ...mapState({
      refScorePrincipal: 'refScorePrincipal',
      refScoreIndepth: 'refScoreIndepth'
    }),
    ...mapGetters('inspectionDamageDialog', { componentUnit: 'componentUnit' }),
    ...mapState({
      refDamageGroup: 'refDamageGroup',
      refDamageIndepthGroup: 'refDamageIndepthGroup'
    }),
    ...mapState('inspectionDamageDialog', {
      has_input_width: 'has_input_width',
      has_input_length: 'has_input_length',
      has_input_height: 'has_input_height',
      has_input_thickness: 'has_input_thickness',
      has_input_piece: 'has_input_piece'
    }),
    areaCritical: {
      get () {
        return this.$store.state.inspectionDamageDialog.areaCritical
      },
      set (to) {
        this.$store.commit('inspectionDamageDialog/SET_AREA_CRITICAL', to)
      }
    },
    damageHeight: {
      get () {
        return this.$store.state.inspectionDamageDialog.damageHeight
      },
      set (to) {
        this.$store.commit('inspectionDamageDialog/SET_DAMAGE_HEIGHT', to)
      }
    },
    damagePiece: {
      get () {
        return this.$store.state.inspectionDamageDialog.damagePiece
      },
      set (to) {
        this.$store.commit('inspectionDamageDialog/SET_DAMAGE_PIECE', to)
      }
    },
    damageTypeId: {
      get () {
        return this.$store.state.inspectionDamageDialog.damageTypeId
      },
      set (to) {
        this.$store.commit('inspectionDamageDialog/SET_DAMAGE_TYPE_ID', to)
      }
    },
    damageWidth: {
      get () {
        return this.$store.state.inspectionDamageDialog.damageWidth
      },
      set (to) {
        this.$store.commit('inspectionDamageDialog/SET_DAMAGE_WIDTH', to)
      }
    },
    damageLength: {
      get () {
        return this.$store.state.inspectionDamageDialog.damageLength
      },
      set (to) {
        this.$store.commit('inspectionDamageDialog/SET_DAMAGE_LENGTH', to)
      }
    },
    damageThickness: {
      get () {
        return this.$store.state.inspectionDamageDialog.damageThickness
      },
      set (to) {
        this.$store.commit('inspectionDamageDialog/SET_DAMAGE_THICKNESS', to)
      }
    },
    hasCritical: {
      get () {
        return this.$store.state.inspectionDamageDialog.hasCritical === 1
      },
      set (to) {
        const value = to ? 1 : 0
        this.$store.commit('inspectionDamageDialog/SET_CRITICAL', value)
        if (!to) {
          this.$store.commit('inspectionDamageDialog/SET_AREA_CRITICAL', 0)
        }
      }
    },
    pointReference: {
      get () {
        return this.$store.state.inspectionDamageDialog.pointReference
      },
      set (to) {
        this.$store.commit('inspectionDamageDialog/SET_POINT_REFERENCE', to)
      }
    },
    scoreId: {
      get () {
        return this.$store.state.inspectionDamageDialog.scoreId
      },
      set (to) {
        this.$store.commit('inspectionDamageDialog/SET_SCORE_ID', to)
      }
    },
    remarks: {
      get () {
        return this.$store.state.inspectionDamageDialog.remarks
      },
      set (to) {
        this.$store.commit('inspectionDamageDialog/SET_REMARKS', to)
      }
    },
    stepTwoFormSuccess: {
      get () {
        return this.$store.state.inspectionDamageDialog.stepTwoFormSuccess
      },
      set (to) {
        this.$store.commit('inspectionDamageDialog/SET_STEP_TWO_VALIDATION', to)
      }
    },
    units: {
      get () {
        return this.$store.state.ref.ref['v-filter-unit-principal']
      }
    },
    principalUnit: {
      get () {
        return this.$store.state.inspectionDamageDialog.principalUnit
      },
      set (to) {
        this.$store.commit('inspectionDamageDialog/SET_PRINCIPAL_UNIT', to)
      }
    },
    severeProblem () {
      const currentScore = this.refScoreByDamageId.find(
        (item) => item.id === this.scoreId
      )
      return [1, 2, 3].includes(parseInt(currentScore?.score))
    },
    damageList () {
      return (this.damageArray || []).map((item) => ({
        ...item,
        name: `${item.name} [${item.id === '0' ? '0000' : parseInt(item.id)}]`
      }))
    },
    refScoreByDamageId () {
      const conditionName = {
        1: 'ดี',
        2: 'ปานกลาง',
        3: 'บกพร่อง',
        4: 'วิกฤต'
      }
      return String(this.inspectionType) === '2'
        ? this.refScorePrincipal
          .filter(
            (item) =>
              String(item.ref_damage_principal_group_id) ===
                String(this.damageTypeId)
          )
          .map((item) => ({
            ...item,
            text: `${item.score} : ${item.name}`,
            id: parseInt(item.id)
          }))
        : this.refScoreIndepth
          .filter(
            (item) =>
              String(item.ref_damage_indepth_group_id) ===
                String(this.damageTypeId)
          )
          .map((item) => ({
            ...item,
            text: `${conditionName[item.ref_score_condition_id]} : ${
                item.name
              }`,
            id: parseInt(item.id)
          }))
    },
    disableAreaCritical () {
      const inspectionType = String(this.inspectionType)
      switch (inspectionType) {
        case '2':
          return !this.hasCritical
        default:
          return false
      }
    },
    damageTypeIdIsZero () {
      return this.damageTypeId === '0'
    },
    damageBackgroundColor () {
      return this.damageTypeIdIsZero ? 'grey lighten-3' : 'white'
    },
    requiredRulesForDamage () {
      if (this.damageTypeIdIsZero) {
        return []
      } else {
        return [
          this.rules.required,
          (d) => d !== '0' || 'area for hasCritical must not be 0',
          this.rules.onlyPositive
        ]
      }
    }
  },
  watch: {
    scoreId () {
      if (this.scoreId) {
        console.log('call damage field 1')
        this.$store.dispatch('inspectionDamageDialog/getDamageIndepthField')
      }
      this.$nextTick(() => {
        this.$refs.form.resetValidation()
      })
    },
    severeProblem (to, from) {
      if (to && !from) {
        this.hasCritical = true
      }
    }
  }
}
</script>

<style scoped>
::v-deep .v-select__selections > input {
  display: none;
}

::v-deep .v-select__selections > .v-select__selection {
  width: 100%;
  max-width: 100%;
}

::v-deep .v-select__selection--comma,
::v-deep .v-list-item__title,
.v-list-item__subtitle {
  text-overflow: unset;
  white-space: normal;
}

.form-grid {
  width: 100%;
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-column-gap: 10px;
  grid-row-gap: 10px;
}

.form-grid_use-row {
  grid-column: 1 / span 2;
}
</style>
