var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"fill-height"},[_c('v-select',{attrs:{"items":_vm.bridgePartArray,"background-color":"primary-light","dense":"","flat":"","hide-details":"","item-text":"name","item-value":"bridgePartId","placeholder":"กรุณาเลือก","solo":""},on:{"change":_vm.changeBridgeEvent},scopedSlots:_vm._u([{key:"selection",fn:function(ref){
var item = ref.item;
return [_c('span',{staticClass:"d-flex justify-center",staticStyle:{"width":"100%"}},[_vm._v(" "+_vm._s(item.name)+" ")])]}}]),model:{value:(_vm.bridgePartId),callback:function ($$v) {_vm.bridgePartId=$$v},expression:"bridgePartId"}}),_c('v-select',{staticClass:"mt-2 px-3",attrs:{"items":_vm.bridgeViewArray,"dense":"","hide-details":"","outlined":""},on:{"change":_vm.resetView},scopedSlots:_vm._u([{key:"selection",fn:function(ref){
var item = ref.item;
return [_c('span',{staticClass:"d-flex justify-center",staticStyle:{"width":"100%"}},[_vm._v(" "+_vm._s(item)+" ")])]}}]),model:{value:(_vm.bridgeView),callback:function ($$v) {_vm.bridgeView=$$v},expression:"bridgeView"}}),_c('v-card-title',{staticClass:"my__v-card-title"},[_c('div',[_c('div',[_vm._v("เลือกชิ้นส่วนสะพาน")]),(!_vm.description)?_c('div',{staticClass:"text-caption",staticStyle:{"margin-top":"-6px"}},[_vm._v(" คลิกที่ภาพชิ้นส่วนที่ต้องการตรวจสอบ ")]):_vm._e(),(_vm.description)?_c('div',{staticClass:"text-caption d-flex align-center",staticStyle:{"margin-top":"-6px"}},[_c('span',{staticClass:"success--text font-weight-bold"},[_vm._v(" "+_vm._s(_vm.getBridge.name + '-' + _vm.description)+" ")]),_c('v-icon',{staticClass:"success white--text rounded-xl ml-1",staticStyle:{"padding":"1px"},attrs:{"x-small":""}},[_vm._v(" mdi-check ")])],1):_vm._e()])]),_c('div',{staticClass:"dataContainer px-3"},[_c('div',{class:{
        bridgeSvgContainer: !_vm.hasText,
        'bridgeSvgContainer-hasText': _vm.hasText
      },staticStyle:{"border":"1px solid #c22d47","border-radius":"5px"}},[(String(_vm.inspectionType) !== '3')?[(_vm.svgStep === 0 && _vm.mountSvg)?_c('BridgeOverview',{staticStyle:{"width":"100%"},attrs:{"accidentMode":false,"brFlag":_vm.brFlag,"bridgeDirection":_vm.bridgeDirection,"bridgePart":_vm.bridgePartComponent,"bridgeType":_vm.bridgeType,"bridgeView":_vm.bridgeView,"endLocation":_vm.endLocation,"bridgeSelected":_vm.getBridge[0],"imageDirection":_vm.$vuetify.breakpoint.smAndUp ? 'horizontal' : 'vertical',"startLocation":_vm.startLocation},on:{"clicked":_vm.setFormParts}}):_vm._e(),(_vm.svgStep === 1 && _vm.mountSvg)?_c('BridgeComponent',{staticStyle:{"width":"100%"},attrs:{"brFlag":_vm.brFlag,"bridgeComponentPartSelected":_vm.bridgeOverviewResult,"bridgeDirection":_vm.bridgeDirection,"bridgeSegmentType":_vm.bridgeSegmentType,"bridgeSelected":_vm.getBridge[0],"bridgeView":_vm.bridgeView,"endLocation":_vm.endLocation,"isIndept":false,"seq":_vm.bridgePartIndex,"startLocation":_vm.startLocation,"bridgePartId":_vm.bridgePartId},on:{"clicked":_vm.setFormSmallParts}}):_vm._e()]:[(_vm.svgStep === 0 && _vm.mountSvg)?_c('BridgeOverview',{staticStyle:{"width":"100%"},attrs:{"accidentMode":false,"brFlag":_vm.brFlag,"bridgeDirection":_vm.bridgeDirection,"bridgePart":_vm.bridgePartComponent,"bridgeSubPartComplete":_vm.bridgeSubPartComplete,"bridgeType":_vm.bridgeType,"bridgeView":_vm.bridgeView,"endLocation":_vm.endLocation,"imageDirection":_vm.$vuetify.breakpoint.smAndUp ? 'horizontal' : 'vertical',"indeptSectorComplete":_vm.tableList,"bridgeSelected":_vm.getBridge[0],"isIndept":['Box Culvert', 'Box Underpass'].includes(
              this.getBridge[0].bridge_strurture_name
            )
              ? !!_vm.tableList
              : true,"selectedPart":_vm.description,"startLocation":_vm.startLocation},on:{"clicked":_vm.setFormParts}}):_vm._e(),(_vm.svgStep === 1 && _vm.mountSvg)?_c('BridgeComponent',{staticStyle:{"width":"100%"},attrs:{"brFlag":_vm.brFlag,"bridgeComponentPartSelected":_vm.bridgeOverviewResult,"bridgeDirection":_vm.bridgeDirection,"bridgeSegmentType":_vm.bridgeSegmentType,"bridgeSelected":_vm.getBridge[0],"bridgeView":_vm.bridgeView,"endLocation":_vm.endLocation,"indeptSectorComplete":_vm.tableList,"isIndept":['Box Culvert', 'Box Underpass'].includes(
              this.getBridge[0].bridge_strurture_name
            )
              ? !!_vm.tableList
              : true,"selectedPart":_vm.description,"seq":_vm.bridgePartIndex,"startLocation":_vm.startLocation,"bridgePartId":_vm.bridgePartId},on:{"clicked":_vm.setFormSmallParts}}):_vm._e()]],2),(_vm.hasText)?_c('div',{staticClass:"textExplain pt-3 pt-sm-0 pl-sm-3"},[(_vm.tableList)?_c('v-simple-table',{staticClass:"iterate-table",staticStyle:{"height":"100%"},attrs:{"fixed-header":""},scopedSlots:_vm._u([{key:"default",fn:function(){return [_c('thead',[(Object.keys(_vm.tableList).length === 0)?_c('tr',[_c('th',{staticStyle:{"font-size":"1rem","color":"black"},attrs:{"colspan":"3","scope":"col"}},[_vm._v(" Error: indepth-component got null value ")])]):(
                _vm.tableListTotal > 0 && Object.keys(_vm.tableList).length > 0
              )?_c('tr',[_c('th',{staticClass:"primary-light",staticStyle:{"font-size":"1rem","color":"black"},attrs:{"scope":"col"}},[_vm._v(" รายละเอียดที่ทำการตรวจสอบแล้วเสร็จ ")]),(_vm.tableList)?_c('th',{staticClass:"primary-light",staticStyle:{"font-size":"1rem","color":"black","white-space":"nowrap"},attrs:{"scope":"col"}},[_vm._v(" "+_vm._s(_vm.tableListChecked)+" / "+_vm._s(_vm.tableListTotal)+" ")]):_c('th',{staticClass:"primary-light",staticStyle:{"font-size":"1rem","color":"black","white-space":"nowrap"},attrs:{"scope":"col"}},[_vm._v(" 0 / 0 ")])]):_c('tr',[_c('th',{staticStyle:{"font-size":"1rem","color":"black"},attrs:{"colspan":"3","scope":"col"}},[_vm._v(" ตรวจสอบแล้วเสร็จ "),_c('v-icon',{staticClass:"success--text ml-1"},[_vm._v(" mdi-check-circle-outline ")])],1)])]),(_vm.tableList)?_c('tbody',{staticStyle:{"font-size":"0.875rem"}},[_vm._l((Object.entries(_vm.tableList)),function(ref){
              var component = ref[0];
              var value = ref[1];
return [(value.countTotal)?_c('tr',{key:component,class:{ expander: value.items.length > 0 },attrs:{"title":value.items.length > 0
                    ? !value.enable
                      ? 'click to expand'
                      : 'click to shrink'
                    : ''},on:{"click":function($event){value.enable = !value.enable}}},[_c('td',[_c('ul',[_c('li',[_vm._v(" "+_vm._s(component)+" ")])])]),_c('td',[_vm._v(" "+_vm._s((value.items || []).length)+" / "+_vm._s(value.countTotal)+" ")])]):_vm._e(),(
                  value.items &&
                  value.countTotal &&
                  _vm.tableList[component].enable
                )?_vm._l((value.items),function(name,index){return _c('tr',{key:component + name + '_' + index},[_c('td',{staticStyle:{"height":"36px"}},[_c('ul',[(value.isSideType)?_c('li',{staticClass:"ml-3"},[_vm._v(" "+_vm._s(name)+" ")]):_c('li',{staticClass:"ml-3"},[_vm._v(" "+_vm._s(component + name)+" ")])])]),_c('td',{staticStyle:{"height":"36px"}})])}):_vm._e()]})],2):_vm._e()]},proxy:true}],null,false,2588467191)}):_vm._e()],1):_vm._e()])],1)}
var staticRenderFns = []

export { render, staticRenderFns }