<template>
  <div class="d-flex pa-2"
       @click="$emit('click')">
    <div class="d-flex align-center">
      <v-img v-if="!imageError && src"
             :src="src"
             height="60"
             width="60"
             @error="imageError=true"/>
      <v-icon v-else
              class="white"
              style="height: 60px; width: 60px;"
              x-large>
        mdi-bridge
      </v-icon>
    </div>
    <div class="ml-4 d-flex flex-column justify-center"
         style="width: 100%; height: 100%;">
      <div class="font-weight-bold">
        {{ name }}
        <v-chip v-if="critical"
                color="red white--text"
                x-small>
          CRITICAL
        </v-chip>
      </div>
      <div v-if="description"
           style="font-size: 0.875rem;">
        {{ description }}
      </div>
      <div style="font-size: 0.875rem;">
        {{ date }}
      </div>
    </div>
    <div :data-text="score"
         class="score">
      {{ score }}
    </div>
  </div>
</template>

<script>
export default {
  name: 'DamageItem',
  props: {
    sector: String,
    src: String,
    name: String,
    date: String,
    critical: Boolean,
    score: String,
    description: String
  },
  data: () => {
    return {
      imageError: false
    }
  }
}
</script>

<style scoped>
.score {
  display: flex;
  align-items: center;
  font-weight: bold;
  font-size: 3rem;
  color: #616161;
  text-align: right;
  white-space: nowrap;
}

.score[data-text="ดี"] {
  color: #84CC16 !important;
  font-size: 1.7rem;
}

.score[data-text="ปานกลาง"] {
  color: #F59E0B !important;
  font-size: 1.7rem;
}

.score[data-text="แย่"] {
  color: #F97316 !important;
  font-size: 1.7rem;
}

.score[data-text="บกพร่อง"] {
  color: #F97316 !important;
  font-size: 1.7rem;
}

.score[data-text="วิกฤต"] {
  color: #EF4444 !important;
  font-size: 1.7rem;
}
</style>
