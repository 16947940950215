<template>
  <v-form v-show="!displayNoneThisForm"
          ref="form"
          v-model="stepTwoDimensionSuccess"
          class="dimension_container">
    <div class="font-weight-bold primary--text"
         style="grid-column: 1 / span 2;">
      Dimension
    </div>
    <div v-if="componentId === 'PR' && ['PC'].includes(bridgePier.pier_type_id)">
      ลักษณะชิ้นส่วน
      <span class="red--text">
        *
      </span>
      <v-select
        v-model="componentPoleId"
        :items="bridgeComponentPole"
        :rules="[rules.required]"
        :disabled="is205Dash"
        class="mt-1"
        dense
        hide-details
        item-text="name"
        item-value="id"
        outlined/>
    </div>
    <div v-if="has_width">
      X (ม.)
      <span class="red--text">
        *
      </span>
      <v-text-field
        v-model="dimension_width"
        :rules="[rules.required, rules.onlyPositive]"
        class="mt-1"
        dense
        hide-details
        outlined
        type="number"
        reverse
        @change="log_input($event, 'dimension_width')"/>
    </div>
    <div v-if="has_length">
      Y (ม.)
      <span class="red--text">
        *
      </span>
      <v-text-field
        v-model="dimension_length"
        :rules="[rules.required, rules.onlyPositive]"
        class="mt-1"
        dense
        hide-details
        outlined
        type="number"
        reverse
        @change="log_input($event, 'dimension_length')"/>
    </div>
    <div v-if="has_height">
      H (ม.)
      <span class="red--text">
        *
      </span>
      <v-text-field
        v-model="dimension_height"
        :rules="[rules.required, rules.onlyPositive]"
        class="mt-1"
        dense
        hide-details
        outlined
        type="number"
        reverse
        @change="log_input($event, 'dimension_height')"/>
    </div>
    <div v-if="has_thickness">
      B (ม.)
      <span class="red--text">
        *
      </span>
      <v-text-field
        v-model="dimension_thickness"
        :rules="[rules.required, rules.onlyPositive]"
        class="mt-1"
        dense
        hide-details
        outlined
        type="number"
        reverse
        @change="log_input($event, 'dimension_thickness')"/>
    </div>
    <div v-if="has_piece">
      ea.
      <span class="red--text">
        *
      </span>
      <v-text-field
        v-model="dimension_piece"
        :rules="[rules.required, rules.onlyPositive]"
        class="mt-1"
        dense
        hide-details
        outlined
        type="number"
        reverse
        @change="log_input($event, 'dimension_piece')"/>
    </div>
  </v-form>
</template>

<script>
import { mapGetters, mapState } from 'vuex'

export default {
  name: 'step-two-dimension',
  data: () => ({
    rules: {
      required: (value) => !!value || 'ต้องการ',
      onlyPositive: (value) => (!isNaN(value) && parseFloat(value) >= 0) || 'ต้องการค่า > 0'
    }
  }),
  methods: {
    toPositive (value, allowZero = false) {
      const n = Number(value)
      if (!allowZero && (parseFloat(n) === 0 || value === '')) return null
      else return n <= 0 ? -1 * n : n
    },
    log_input (e, keyName) {
      this.$nextTick(() => {
        const allowZero = false
        const newVal = this.toPositive(e, allowZero)
        if (newVal === null) {
          this[keyName] = 9999
          this.$nextTick(() => {
            this[keyName] = null
          })
        } else {
          this[keyName] = newVal
        }
      })
    }
  },
  computed: {
    ...mapState('inspectionDamageDialog', { requiredMaterial: 'requiredMaterial', componentId: 'componentId', sectorNo: 'sectorNo', materialId: 'materialId', elementNoWeight: 'elementNoWeight' }),
    ...mapState('inspectionDamageDialog', { has_width: 'has_width', has_length: 'has_length', has_height: 'has_height', has_thickness: 'has_thickness', has_piece: 'has_piece' }),
    ...mapState('ref', { ref: 'ref' }),
    ...mapGetters('inspectionDamageDialog', { currentPier: 'currentPier' }),
    componentPoleId: {
      get () {
        return this.$store.state.inspectionDamageDialog.componentPoleId
      },
      set (to) {
        this.$store.commit('inspectionDamageDialog/SET_SELECTED_POLE', to)
      }
    },
    dimension_width: {
      get () {
        return this.$store.state.inspectionDamageDialog.dimension_width
      },
      set (to) {
        this.$store.commit('inspectionDamageDialog/SET_DIMENSION_WIDTH', to)
      }
    },
    dimension_length: {
      get () {
        return this.$store.state.inspectionDamageDialog.dimension_length
      },
      set (to) {
        this.$store.commit('inspectionDamageDialog/SET_DIMENSION_LENGTH', to)
      }
    },
    dimension_height: {
      get () {
        return this.$store.state.inspectionDamageDialog.dimension_height
      },
      set (to) {
        this.$store.commit('inspectionDamageDialog/SET_DIMENSION_HEIGHT', to)
      }
    },
    dimension_thickness: {
      get () {
        return this.$store.state.inspectionDamageDialog.dimension_thickness
      },
      set (to) {
        this.$store.commit('inspectionDamageDialog/SET_DIMENSION_THICKNESS', to)
      }
    },
    dimension_piece: {
      get () {
        return this.$store.state.inspectionDamageDialog.dimension_piece
      },
      set (to) {
        this.$store.commit('inspectionDamageDialog/SET_DIMENSION_PIECE', to)
      }
    },
    stepTwoDimensionSuccess: {
      get () {
        return this.$store.state.stepTwoDimensionSuccess
      },
      set (to) {
        this.$store.commit('inspectionDamageDialog/SET_STEP_TWO_DIMENSION_SUCCESS', to)
      }
    },
    bridgePier () {
      return this.currentPier
    },
    dimensionFieldWatcher () { // use in watcher
      return this.componentId + this.materialId + this.componentPoleId
    },
    bridgeComponentPole () {
      return this.ref['bridge-component-pole']
    },
    displayNoneThisForm () {
      const hasPoleId = ['PC'].includes(this.componentId)
      const hasDimension = this.has_width || this.has_thickness || this.has_piece || this.has_piece || this.has_height || this.has_length
      return !hasPoleId && !hasDimension
    },
    damageTypeFetchWatcher () {
      return this.materialId + this.componentPoleId
    },
    is205Dash () {
      return this.elementNoWeight === '205-1' || this.elementNoWeight === '205-2'
    }
  },
  watch: {
    dimensionFieldWatcher () {
      if (['PC'].includes(this.componentId) && !this.componentPoleId) {
        return false
      }
      if (!this.componentId || !this.materialId) {
        return false
      }
      this.$store.dispatch('inspectionDamageDialog/getDimensionField')
    },
    damageTypeFetchWatcher (to, from) {
      this.$store.dispatch('inspectionDamageDialog/fetchDamageMapping', { component_id: this.componentId })
    }
  }
}
</script>

<style scoped>
.dimension_container {
  width: 100%;
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-column-gap: 10px;
  grid-row-gap: 10px;
}

::v-deep .v-select__selections > input {
  display: none;
}

::v-deep .v-select__selections > .v-select__selection {
  width: 100%;
  max-width: 100%;
}

::v-deep .v-select__selection--comma, ::v-deep .v-list-item__title, .v-list-item__subtitle {
  text-overflow: unset;
  white-space: normal;
}
</style>
