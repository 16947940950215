<template>
  <div class="fill-height">
    <div class="d-flex"
         style="width: 100%;">
      <v-tabs v-model="tab"
              style="flex: 1 1 auto;">
        <v-tab>
          รายการความเสียหาย
        </v-tab>
        <v-tab v-if="parseInt(inspectionType) !== 4">
          สรุปความเสียหาย
        </v-tab>
      </v-tabs>
      <div class="d-flex align-center pr-3"
           style="height: 48px;">
        <v-autocomplete
          v-if="!loading && tab === 0"
          v-model="selectedSector"
          :items="availableSector"
          dense hide-details
          item-text="text"
          return-object
          style="width: 140px;"
          placeholder="ค้นหา sector"
          class="mr-3 search-sector-autocomplete"
          clearable
          @change="$store.dispatch('inspectionDamageDialog/refreshInspection', $event);"
        />
        <v-btn v-if="can_add_inspection"
               class="d-none d-sm-inline-block mr-2"
               color="blue white--text"
               elevation="0"
               @click="newDamage">
          <v-icon>
            mdi-plus
          </v-icon>
          เพิ่มการตรวจสอบ
        </v-btn>
        <v-btn class="primary--text white ml-2"
               elevation="2"
               icon
               @click="$store.dispatch('inspectionDamageDialog/refreshInspection'); updateTable($event);">
          <v-icon>
            mdi-refresh
            {{ (loading) ? 'mdi-spin-fast' : '' }}
          </v-icon>
        </v-btn>
      </div>
    </div>
    <v-tabs-items v-model="tab"
                  touchless>
      <v-tab-item>
        <div ref="parent"
             id="overflowTarget"
             style="height: 100%; overflow-y: auto; position: relative;">
          <template v-for="sector in inspectionItemList">
            <template v-for="sectorNumber in sector.items">
              <template v-for="component in sectorNumber.items">
                <div :key="sector.sector_id + sectorNumber.sector_no + '-' + component.component_id"
                     :class="isParentOverflown ? 'component-overflown' : 'component'"
                     class="section-item drop-shadow">
                  <div class="primary--text font-weight-bold pa-2"
                       style="font-size: 1.1rem;">
                    {{ sector.sector_id + sectorNumber.sector_no + '-' + component.component_id }}
                    ({{ component.component_name }})
                  </div>
                  <div v-for="damage in component.items"
                       :key="damage.id"
                       class="component-item">
                    <damage-item
                      :critical="(damage.hasCritical || !!damage.area_critical) && parseInt(inspectionType) === 2"
                      :date="dateToThai(stringToDate(String(damage.dateUpdated).slice(0, 10)))"
                      :description="`${damage.bridge_part_code}-${sector.sector_id}${sectorNumber.sector_no}-${component.component_id}${damage.component_no}`"
                      :name="damage.type.name"
                      :score="damage.score.score || damage.score.condition"
                      :sector="component.component_id"
                      :src="damage.pathThumbnail"
                      class="pointer-hover light-blue-hover damage-item"
                      @click="getEvent({ ...damage, component_id: component.component_id })"/>
                  </div>
                </div>
              </template>
            </template>
          </template>
          <div v-if="!inspectionSummarySuccess"
               class="pa-2 rounded mx-4 mt-3"
               style="width: calc(100% - 32px); font-size: 0.875rem; background: #FFF7AB; position: sticky; bottom: 0; border: 1px solid #BCA900;">
            <v-icon class="red--text">mdi-alert-outline</v-icon>
            การตรวจสอบยังไม่ครบทุกองค์ประกอบ ตรวจสอบได้ที่แท็บ
            <a class="blue--text font-weight-bold underline-hover" @click="tab=1">สรุปความเสียหาย</a>
          </div>
          <div
            class="d-block d-sm-none"
            data-explain="padding reserve for plus btn"
            style="height: 60px;"/>
        </div>
      </v-tab-item>
      <v-tab-item v-if="parseInt(inspectionType) !== 4">
        <template v-if="parseInt(inspectionType) === 2">
          <div style="height: 100%; overflow-y: auto;">
            <div class="text-right red--text pr-3 pt-1" style="font-size: 0.875rem;">
              * รอการตรวจสอบ
            </div>
            <step-zero-principal-table
              :table="inspectionSummary.condition_rating"
              class="drop-shadow ma-3"
              title="สภาพความเสียหาย Condition Rating"/>
            <step-zero-principal-table
              :table="inspectionSummary.appraisal_rating"
              class="drop-shadow ma-3"
              title="การประเมินสภาวะการใช้งาน Appraisal Rating"/>
            <div
              class="d-block d-sm-none"
              data-explain="padding reserve for plus btn"
              style="height: 60px;"/>
          </div>
        </template>
        <template v-else>
          <step-zero-any-table ref="summaryTable"/>
        </template>
      </v-tab-item>
    </v-tabs-items>
  </div>
</template>

<script>
import DamageItem from '@/components/inspection/InspectionDialog/DamageItem'
import StepZeroAnyTable from '@/components/inspection/InspectionDialog/StepForm/StepZeroAnyTable'
import { mapGetters, mapState } from 'vuex'
import utils from '@/assets/js/utils'

export default {
  name: 'StepZero',
  components: {
    StepZeroAnyTable,
    StepZeroPrincipalTable: () => import(/* webpackChunkName: "step-zero-principal-table" */ '@/components/inspection/InspectionDialog/StepForm/StepZeroPrincipalTable'),
    DamageItem
  },
  data: () => ({
    stringToDate: utils.stringToDate,
    dateToThai: utils.dateToThai,
    isParentOverflown: false,
    tab: 0,
    inspectionSummarySuccess: true,
    selectedSector: null
  }),
  methods: {
    newDamage () {
      this.$store.dispatch('inspectionDamageDialog/newDamageForm')
      this.$store.commit('inspectionDamageDialog/RESET_DIMENSION')
    },
    async getEvent (payload) {
      // Is this callback hell ? old code
      // this.$store.dispatch('inspectionDamageDialog/backupScrollDistance')
      // this.$store.commit('inspectionDamageDialog/RESET_DIMENSION')
      // this.$store.dispatch('inspectionDamageDialog/fetchPrincipalForm', payload)
      //   .then(data => {
      //     this.$store.dispatch('inspectionDamageDialog/fetchElementNoWeight', { component_id: data.component.id })
      //     this.$store.dispatch('inspectionDamageDialog/fetchComponentDimension', payload)
      //       .then(() => {
      //         this.$store.dispatch('inspectionDamageDialog/getDamageIndepthField')
      //         this.$store.dispatch('inspectionDamageDialog/fetchDamageMapping', { component_id: payload.component_id })
      //       })
      //   })

      await this.$store.dispatch('inspectionDamageDialog/backupScrollDistance')
      await this.$store.commit('inspectionDamageDialog/RESET_DIMENSION')
      const data = await this.$store.dispatch('inspectionDamageDialog/fetchPrincipalForm', payload)
      await this.$store.dispatch('inspectionDamageDialog/fetchElementNoWeight', { component_id: data.component.id })
      await this.$store.dispatch('inspectionDamageDialog/fetchComponentDimension', payload)
      await this.$store.dispatch('inspectionDamageDialog/getDamageIndepthField')
      await this.$store.dispatch('inspectionDamageDialog/fetchDamageMapping', { component_id: payload.component_id })
    },
    computeOverflown () {
      const el = this.$refs.parent
      const scrollbarWidth = (el.offsetWidth - el.clientWidth)
      const isTouchAgent = scrollbarWidth <= 0
      this.isParentOverflown = utils.isOverflown(this.$refs.parent) && !isTouchAgent
    },
    updateTable (e) {
      const component = this.$refs.summaryTable
      if (component) {
        component.updateTable(e)
      }
    }
  },
  computed: {
    ...mapState('inspectionDamageDialog', { inspectionItemList: 'inspectionItemList', inspectionType: 'inspectionType', inspectionSummary: 'inspectionSummary' }),
    ...mapState('inspectionDamageDialog', { loading: 'loading', drawer: 'drawer' }),
    ...mapGetters('inspectionDamageDialog', { availableSector: 'availableSector' }),
    ...mapGetters('bridgeDetail', { canAdd_permission: 'canAdd_permission' }),
    ...mapGetters({ user_can_add_inspection: 'can_add_inspection' }),
    can_add_inspection () {
      return this.user_can_add_inspection && this.canAdd_permission
    }
  },
  watch: {
    inspectionSummary () {
      if (parseInt(this.inspectionType) === 2) {
        const bool = [...this.inspectionSummary.condition_rating, ...this.inspectionSummary.appraisal_rating].every(item => item.warn !== true)
        this.inspectionSummarySuccess = bool
        this.$emit('update:inspectionSummaryDone', bool)
      } else {
        this.inspectionSummarySuccess = true
        this.$emit('update:inspectionSummaryDone', true)
      }
    },
    loading () {
      this.computeOverflown()
      this.$nextTick(this.computeOverflown)
    },
    tab () {
      setTimeout(() => {
        if (this.$refs.summaryTable) {
          this.$refs.summaryTable.selectedComponent = -1
          this.$refs.summaryTable.updateTable()
        }
        this.computeOverflown()
        this.$nextTick(this.computeOverflown)
      }, 50)
    },
    step () {
      setTimeout(() => {
        this.computeOverflown()
        this.$nextTick(this.computeOverflown)
      }, 100)
    },
    drawer (to, from) {
      if (to) {
        this.tab = 0
        if (this.$refs.summaryTable) {
          this.$refs.summaryTable.selectedComponent = -1
          this.$refs.summaryTable.updateTable()
        }
      } else {
        setTimeout(() => {
          this.tab = 0
        }, 200)
      }
    }
  }
}
</script>

<!-- for better performance, split style scoped and style  -->

<style>
.section-item {
  border-radius: 5px;
}

.damage-item {
  content-visibility: auto;
  contain-intrinsic-size: 82px;
}

.component {
  margin: 12px 16px;
}

.component-overflown {
  margin: 12px 4px 12px 16px;
}

.component-item:nth-child(even) {
  background: #F6F6F6;
}

.component-item:last-child {
  border-radius: 0 0 5px 5px;
}
</style>

<style scoped>
::v-deep .v-tabs, ::v-deep .v-window__container, ::v-deep .v-window-item {
  height: 100%;
}

::v-deep .v-tabs-items {
  height: calc(100% - 48px);
}

::v-deep .v-slide-group__prev {
  display: none !important;
}

.search-sector-autocomplete {
  margin-top: 8px;
}

::v-deep .search-sector-autocomplete input {
  padding-bottom: 8px;
}
</style>
