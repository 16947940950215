<template>
  <v-card>
    <v-card-title class="my__v-card-title primary white--text">
      <div>
        <div>
          {{ surveyTypeName(inspectionType) }}
        </div>
        <div class="text-caption"
             style="margin-top: -6px;">
          {{ $store.state.appbarText.header }} --- {{ $store.state.appbarText.subHeader }}
        </div>
      </div>
      <v-icon color="white"
              @click="close">
        mdi-close
      </v-icon>
    </v-card-title>
    <v-card-text :class="{ 'v-card-text__parent-step2': (step === 2) }"
                 class="my__v-card-text"
                 style="position: relative;">
      <step-zero v-show="step === 0"
                 ref="stepZero"
                 @update:inspectionSummaryDone="inspectionSummaryDoneCb"/>
      <v-btn v-if="step === 0"
             :style="addBtnRightPosition"
             class="d-sm-none white--text primary v-card-text__add-btn"
             elevation="2"
             icon
             x-large
             @click="addNewEvent">
        <v-icon>
          mdi-plus
        </v-icon>
      </v-btn>
      <step-one
        v-if="step === 1"
        ref="stepOne"/>
      <template v-if="step === 2">
        <step-two-carousel class="v-card-text__carousel__container-step2"/>
        <step-two class="v-card-text__form__container-step2"/>
      </template>
    </v-card-text>
    <step-zero-actions
      v-if="step === 0"
      :allComponentFinish="allComponentFinish"
      @gotoTab="gotoTab"/>
    <step-actions v-else/>
    <history-dialog/>
    <suggestion-dialog/>
  </v-card>
</template>

<script>
import { mapGetters, mapState } from 'vuex'
import StepZero from '@/components/inspection/InspectionDialog/StepForm/StepZero'
import StepOne from '@/components/inspection/InspectionDialog/StepForm/StepOne'
import StepTwo from '@/components/inspection/InspectionDialog/StepForm/StepTwo'
import utils from '@/assets/js/utils'

export default {
  name: 'OtherFormMobile',
  components: {
    StepZero,
    StepOne,
    StepTwo,
    StepZeroActions: () => import('@/components/inspection/InspectionDialog/StepForm/StepZeroActions'),
    StepActions: () => import('@/components/inspection/InspectionDialog/StepForm/StepActions'),
    SuggestionDialog: () => import('@/components/inspection/InspectionDialog/StepForm/SuggestionDialog'),
    HistoryDialog: () => import('@/components/inspection/InspectionDialog/StepForm/HistoryDialog'),
    StepTwoCarousel: () => import('@/components/inspection/InspectionDialog/StepForm/StepTwoCarousel')
  },
  mounted () {
    this.$store.dispatch('getComponentIndepthGroup')
    this.$store.dispatch('getRefScorePrincipal')
    this.$store.dispatch('getRefScoreIndepth')
    this.$store.dispatch('getRefDamageGroup')
    this.$store.dispatch('getRefDamageIndepthGroup')
    this.$nextTick(this.computeStepZeroOverflown)
    this.$store.commit('inspectionDamageDialog/STEP_ZERO_COMPONENT', this.$refs.stepZero)
  },
  data: () => ({
    stepZeroOverflown: false,
    deletePrincipalDialog: false,
    deleteResult: null,
    allComponentFinish: false
  }),
  methods: {
    close () {
      this.$store.commit('inspectionDamageDialog/SVG_STEP', 0)
      if (this.step === 0) {
        this.$store.commit('inspectionDamageDialog/SET_DRAWER', 0)
        this.$store.dispatch('bridgeDetail/getInspectionList', { id: this.bridgeId, force: true })
      } else {
        this.$store.commit('inspectionDamageDialog/SET_STEP', 0)
      }
    },
    addNewEvent () {
      this.$store.dispatch('inspectionDamageDialog/newDamageForm')
    },
    computeStepZeroOverflown () {
      const stepZero = this.$refs.stepZero
      if (stepZero) {
        this.stepZeroOverflown = utils.isOverflown(stepZero.$el)
      } else {
        this.stepZeroOverflown = false
      }
    },
    inspectionSummaryDoneCb (bool) {
      this.allComponentFinish = bool
    },
    gotoTab (tab) {
      this.$refs.stepZero.tab = tab
    }
  },
  computed: {
    ...mapState('inspectionDamageDialog', { drawer: 'drawer', step: 'step' }),
    ...mapState('inspectionDamageDialog', { inspectionType: 'inspectionType', inspectionItemList: 'inspectionItemList', principalId: 'principalId', bridgeId: 'bridgeId', componentId: 'componentId' }),
    ...mapGetters('damageDetailSupply', { surveyTypeName: 'surveyTypeName' }),
    addBtnRightPosition () {
      return (this.stepZeroOverflown && this.$vuetify.breakpoint.smAndUp)
        ? 'right: 30px;'
        : 'right: 12px;'
    },
    overFlownWatcher () {
      return this.drawer + this.step
    }
  },
  watch: {
    drawer (to, from) {
      if (!to && from) {
        setTimeout(() => {
          this.$store.commit('inspectionDamageDialog/STEP_RESET')
        }, 200)
      }
    },
    overFlownWatcher () {
      this.$nextTick(this.computeStepZeroOverflown)
    },
    inspectionItemList: {
      deep: true,
      handler () {
        this.$nextTick(this.computeStepZeroOverflown)
      }
    },
    step () {
      this.$nextTick(() => {
        this.$store.commit('inspectionDamageDialog/STEP_ZERO_COMPONENT', this.$refs.stepZero)
        this.$store.commit('inspectionDamageDialog/STEP_ONE_COMPONENT', this.$refs.stepOne)
      })
    }
  }
}
</script>

<style scoped>
.v-card-text__add-btn {
  position: absolute;
  bottom: 4px;
}

@media screen and (min-width: 600px) {
  .v-card-text__parent-step2 {
    display: flex;
  }

  .v-card-text__carousel__container-step2 {
    width: calc(100% - 450px);
    margin: auto 0;
  }

  .v-card-text__form__container-step2 {
    width: 450px;
    overflow-y: auto;
  }
}
</style>
