<template>
  <div class="fill-height">
    <v-select
      v-model="bridgePartId"
      :items="bridgePartArray"
      background-color="primary-light"
      dense
      flat
      hide-details
      item-text="name"
      item-value="bridgePartId"
      placeholder="กรุณาเลือก"
      solo
      @change="changeBridgeEvent"
    >
      <template v-slot:selection="{ item }">
        <span class="d-flex justify-center" style="width: 100%">
          {{ item.name }}
        </span>
      </template>
    </v-select>
    <v-select
      v-model="bridgeView"
      :items="bridgeViewArray"
      class="mt-2 px-3"
      dense
      hide-details
      outlined
      @change="resetView"
    >
      <template v-slot:selection="{ item }">
        <span class="d-flex justify-center" style="width: 100%">
          {{ item }}
        </span>
      </template>
    </v-select>
    <v-card-title class="my__v-card-title">
      <div>
        <div>เลือกชิ้นส่วนสะพาน</div>
        <div v-if="!description" class="text-caption" style="margin-top: -6px">
          คลิกที่ภาพชิ้นส่วนที่ต้องการตรวจสอบ
        </div>
        <div
          v-if="description"
          class="text-caption d-flex align-center"
          style="margin-top: -6px"
        >
          <span class="success--text font-weight-bold">
            {{ getBridge.name + '-' + description }}
          </span>
          <v-icon
            class="success white--text rounded-xl ml-1"
            style="padding: 1px"
            x-small
          >
            mdi-check
          </v-icon>
        </div>
      </div>
    </v-card-title>
    <div class="dataContainer px-3">
      <div
        :class="{
          bridgeSvgContainer: !hasText,
          'bridgeSvgContainer-hasText': hasText
        }"
        style="border: 1px solid #c22d47; border-radius: 5px"
      >
        <template v-if="String(inspectionType) !== '3'">
          <BridgeOverview
            v-if="svgStep === 0 && mountSvg"
            :accidentMode="false"
            :brFlag="brFlag"
            :bridgeDirection="bridgeDirection"
            :bridgePart="bridgePartComponent"
            :bridgeType="bridgeType"
            :bridgeView="bridgeView"
            :endLocation="endLocation"
            :bridgeSelected="getBridge[0]"
            :imageDirection="
              $vuetify.breakpoint.smAndUp ? 'horizontal' : 'vertical'
            "
            :startLocation="startLocation"
            style="width: 100%"
            @clicked="setFormParts"
          />
          <BridgeComponent
            v-if="svgStep === 1 && mountSvg"
            :brFlag="brFlag"
            :bridgeComponentPartSelected="bridgeOverviewResult"
            :bridgeDirection="bridgeDirection"
            :bridgeSegmentType="bridgeSegmentType"
            :bridgeSelected="getBridge[0]"
            :bridgeView="bridgeView"
            :endLocation="endLocation"
            :isIndept="false"
            :seq="bridgePartIndex"
            :startLocation="startLocation"
            :bridgePartId="bridgePartId"
            style="width: 100%"
            @clicked="setFormSmallParts"
          />
        </template>
        <template v-else>
          <BridgeOverview
            v-if="svgStep === 0 && mountSvg"
            :accidentMode="false"
            :brFlag="brFlag"
            :bridgeDirection="bridgeDirection"
            :bridgePart="bridgePartComponent"
            :bridgeSubPartComplete="bridgeSubPartComplete"
            :bridgeType="bridgeType"
            :bridgeView="bridgeView"
            :endLocation="endLocation"
            :imageDirection="
              $vuetify.breakpoint.smAndUp ? 'horizontal' : 'vertical'
            "
            :indeptSectorComplete="tableList"
            :bridgeSelected="getBridge[0]"
            :isIndept="
              ['Box Culvert', 'Box Underpass'].includes(
                this.getBridge[0].bridge_strurture_name
              )
                ? !!tableList
                : true
            "
            :selectedPart="description"
            :startLocation="startLocation"
            style="width: 100%"
            @clicked="setFormParts"
          />
          <BridgeComponent
            v-if="svgStep === 1 && mountSvg"
            :brFlag="brFlag"
            :bridgeComponentPartSelected="bridgeOverviewResult"
            :bridgeDirection="bridgeDirection"
            :bridgeSegmentType="bridgeSegmentType"
            :bridgeSelected="getBridge[0]"
            :bridgeView="bridgeView"
            :endLocation="endLocation"
            :indeptSectorComplete="tableList"
            :isIndept="
              ['Box Culvert', 'Box Underpass'].includes(
                this.getBridge[0].bridge_strurture_name
              )
                ? !!tableList
                : true
            "
            :selectedPart="description"
            :seq="bridgePartIndex"
            :startLocation="startLocation"
            :bridgePartId="bridgePartId"
            style="width: 100%"
            @clicked="setFormSmallParts"
          />
        </template>
      </div>
      <div v-if="hasText" class="textExplain pt-3 pt-sm-0 pl-sm-3">
        <v-simple-table
          v-if="tableList"
          class="iterate-table"
          fixed-header
          style="height: 100%"
        >
          <template v-slot:default>
            <thead>
              <tr v-if="Object.keys(tableList).length === 0">
                <th
                  colspan="3"
                  scope="col"
                  style="font-size: 1rem; color: black"
                >
                  Error: indepth-component got null value
                </th>
              </tr>
              <tr
                v-else-if="
                  tableListTotal > 0 && Object.keys(tableList).length > 0
                "
              >
                <th
                  class="primary-light"
                  scope="col"
                  style="font-size: 1rem; color: black"
                >
                  รายละเอียดที่ทำการตรวจสอบแล้วเสร็จ
                </th>
                <th
                  v-if="tableList"
                  class="primary-light"
                  scope="col"
                  style="font-size: 1rem; color: black; white-space: nowrap"
                >
                  {{ tableListChecked }}
                  /
                  {{ tableListTotal }}
                </th>
                <th
                  v-else
                  class="primary-light"
                  scope="col"
                  style="font-size: 1rem; color: black; white-space: nowrap"
                >
                  0 / 0
                </th>
              </tr>
              <tr v-else>
                <th
                  colspan="3"
                  scope="col"
                  style="font-size: 1rem; color: black"
                >
                  ตรวจสอบแล้วเสร็จ
                  <v-icon class="success--text ml-1">
                    mdi-check-circle-outline
                  </v-icon>
                </th>
              </tr>
            </thead>
            <tbody v-if="tableList" style="font-size: 0.875rem">
              <template v-for="[component, value] in Object.entries(tableList)">
                <tr
                  v-if="value.countTotal"
                  :key="component"
                  :class="{ expander: value.items.length > 0 }"
                  :title="
                    value.items.length > 0
                      ? !value.enable
                        ? 'click to expand'
                        : 'click to shrink'
                      : ''
                  "
                  @click="value.enable = !value.enable"
                >
                  <td>
                    <ul>
                      <li>
                        {{ component }}
                      </li>
                    </ul>
                  </td>
                  <td>
                    {{ (value.items || []).length }} / {{ value.countTotal }}
                  </td>
                </tr>
                <template
                  v-if="
                    value.items &&
                    value.countTotal &&
                    tableList[component].enable
                  "
                >
                  <tr
                    v-for="(name, index) in value.items"
                    :key="component + name + '_' + index"
                  >
                    <td style="height: 36px">
                      <ul>
                        <li v-if="value.isSideType" class="ml-3">
                          {{ name }}
                        </li>
                        <li v-else class="ml-3">
                          {{ component + name }}
                        </li>
                      </ul>
                    </td>
                    <td style="height: 36px"></td>
                  </tr>
                </template>
              </template>
            </tbody>
          </template>
        </v-simple-table>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters, mapState } from 'vuex'
import BridgeOverview from '@/components/bridge/bridgeOverview'
import BridgeComponent from '@/components/bridge/bridgeComponent'
import api from '@/assets/js/api'
import utils from '@/assets/js/utils'

export default {
  name: 'StepOne',
  components: { BridgeComponent, BridgeOverview },
  mounted () {
    if (!this.bridgePartId) {
      if (this.bridgePartArray[1]) {
        this.bridgePartId = this.bridgePartArray[1].bridgePartId
      } else {
        this.bridgePartId = this.bridgePartArray[0].bridgePartId
      }
    }
    setTimeout(this.changeBridgeEvent, 100)
    if (this.bridgeView === '') {
      this.bridgeView = 'topView'
    }
    if (
      ['Box Culvert', 'Box Underpass'].includes(
        this.getBridge[0].bridge_strurture_name
      )
    ) {
      this.fetchOtherPart({ sector_id: '' })
    }
  },
  data () {
    return {
      // bridgeView: '', Vuex binding
      bridgeViewArray: [],
      brFlag: 1,
      mountSvg: false,
      // bridge svg
      startLocation: '',
      endLocation: '',
      bridgeDirection: '',
      // bridgeOverview
      bridgePartComponent: [],
      bridgeType: null,
      // bridgeComponent
      bridgeOverviewResult: {},
      bridgePartIndex: 0,
      bridgeSegmentType: null,
      // --
      sectorId: '',
      sectorNo: '',
      tableList: null,
      tableListTotal: 0,
      tableListChecked: 0,
      bridgeSubPartComplete: [],
      partCode: '', // type ==> L1, R1, or ....
      indeptSectorComplete: []
    }
  },
  methods: {
    async setFormParts (parts) {
      console.log('part select')
      this.sectorId = parts.sectorID
      this.sectorNo = parts.sectorNo
      // --
      if (this.bridgeView === 'sideView') {
        this.setFormSmallParts(parts)
        await this.getIndepthComponentComplete()
        return
      }
      // --
      this.bridgeOverviewResult = parts
      const componentNumber = parseInt(parts.componentNo)
      this.bridgePartIndex = componentNumber
      // --
      const componentIsP = parts.componentID === 'P'
      const componentIsA =
        parts.componentID === 'A' && this.bridgeView === 'bottomView'
      const componentIsS = parts.componentID === 'S' || componentNumber === 1
      const changeToCrossSection = componentIsP || componentIsA
      if (changeToCrossSection) {
        this.bridgeView = 'crossSectionView'
        this.bridgeViewArray = ['crossSectionView']
        this.bridgeSegmentType =
          this.getBridge.bridge_segment[componentNumber - 1].segment_type_id
      } else {
        this.bridgeSegmentType = 'BG'
      }
      this.brFlag = componentIsS ? 1 : 0
      if (parts.sectorID === 'J') {
        this.setFormSmallParts(parts)
        this.$nextTick(() => {
          this.mountSvg = true
        })
        return
      } else {
        this.svgStep = 1
        this.fetchOtherPart({ sector_id: parts.componentID })
        await this.getIndepthComponentComplete()
      }
      this.$store.commit('inspectionDamageDialog/SET_PRINCIPAL_SVG', {
        sectorID: parts.sectorID,
        sectorNo: parts.sectorNo
      })
      this.$nextTick(() => {
        this.mountSvg = true
      })
    },
    // eslint-disable-next-line camelcase
    fetchOtherPart ({ sector_id }) {
      const payload = {
        sector_id: sector_id,
        bridge_structure_id: this.getBridge[0].bridge_strurture_id
      }
      this.$store.dispatch('inspectionDamageDialog/getOtherComponent', payload)
    },
    async getIndeptSectorComplete (partCode) {
      let bridgeViewWs = 'top'
      switch (this.bridgeView) {
        case 'topView':
          bridgeViewWs = 'top'
          break
        case 'bottomView':
          bridgeViewWs = 'bottom'
          break
        case 'sideView':
          bridgeViewWs = 'side'
          break
      }
      this.indeptSectorComplete = []
      this.indeptSectorComplete = await this.$store.dispatch(
        'getIndeptSectorComplete',
        {
          inspectionId: this.inspectionId,
          bridgeView: bridgeViewWs,
          partCode: partCode
        }
      )
      console.log('this.indeptSectorComplete')
      if (this.indeptSectorComplete != null) {
        for (let i = 0; i < this.indeptSectorComplete.length; i++) {
          for (let j = 0; j < this.indeptSectorComplete[i].items.length; j++) {
            for (let k = 0; k < this.bridgePartComponent.length; k++) {
              if (
                this.indeptSectorComplete[i].sector_id ===
                  this.bridgePartComponent[k].segment &&
                (
                  this.indeptSectorComplete[i].items[j].sector_no || ''
                ).toString() === this.bridgePartComponent[k].seq.toString()
              ) {
                this.bridgePartComponent[k].isCompleted =
                  this.indeptSectorComplete[i].items[j].isCompleted
              }
            }
            for (let l = 0; l < this.bridgeSubPartComplete.length; l++) {
              if (
                this.indeptSectorComplete[i].sector_id ===
                  this.bridgeSubPartComplete[l].segment &&
                (
                  this.indeptSectorComplete[i].items[j].sector_no || ''
                ).toString() === this.bridgeSubPartComplete[l].seq.toString()
              ) {
                console.log(this.indeptSectorComplete[i].items[j].isCompleted)
                this.bridgeSubPartComplete[l].isCompleted =
                  this.indeptSectorComplete[i].items[j].isCompleted
              }
            }
          }
        }
      }
      console.log(this.indeptSectorComplete)
      console.log(this.tableList)
      console.log(this.bridgePartComponent)
      console.log(this.bridgeSubPartComplete)
      console.log('end')
      return true
    },
    async getIndepthComponentComplete () {
      let renewSVG = false
      if (this.tableList === null) {
        renewSVG = true
      }
      let apiName = ''
      switch (this.bridgeView) {
        case 'topView':
          apiName = 'top'
          break
        case 'bottomView':
          apiName = 'bottom'
          break
        case 'sideView':
          apiName = 'side'
          break
        case 'crossSectionView':
          apiName = 'bottom'
          break
      }
      const sideType = ['Box Culvert', 'Box Underpass']
      const isSideType = sideType.includes(
        this.getBridge[0].bridge_strurture_name
      )
      const apiPath = isSideType ? 'indepth-sector' : 'indepth-component'
      const url = `${process.env.VUE_APP_WS_BASE}/get/${apiPath}/${this.inspectionId}/${apiName}`
      const payload = {
        partCode: this.partCode,
        sectorId: this.sectorId,
        sectorNo: this.sectorNo
      }
      const formData = utils.payloadToFormData(payload)
      const response = await api.post({ url, data: formData })
      const ret = response.data.data
      if (ret) {
        if (isSideType) {
          const data = {}
          ret.forEach(({ items }) => {
            /* eslint-disable */
            items.forEach(({ sector_no, components }) => {
              Object.entries(components).forEach(([key, value]) => {
                if (!data[key]) {
                  data[key] = {
                    countTotal: 0,
                    items: [],
                    enable: true,
                    isSideType: true
                  }
                }
                data[key].countTotal += value.countTotal
                value.items.forEach((number) => {
                  switch (key) {
                    case 'CV':
                      data[key].items.push(`CV${sector_no}-${number}`)
                      break
                    case 'ST':
                      data[key].items.push(`S${sector_no}-T`)
                      break
                    case 'T':
                      data[key].items.push(`S${sector_no}-T`)
                      break
                    case 'SB':
                      data[key].items.push(`S${sector_no}-B`)
                      break
                    case 'B':
                      data[key].items.push(`S${sector_no}-B`)
                      break
                    case 'WW':
                      data[key].items.push(`WW${sector_no}`)
                      break
                    default:
                      data[key].items.push(`${key}${sector_no}-${number}`)
                      break
                  }
                })
              })
            })
            /* eslint-enable */
          })
          this.tableList = data
        } else {
          const data = ret.components || {}
          Object.keys(data).forEach((key) => {
            data[key].enable = true
          })
          this.tableList = data
        }
        this.tableListChecked = Object.values(this.tableList).reduce(
          (acc, cur) => acc + (cur.items || []).length,
          0
        )
        this.tableListTotal = Object.values(this.tableList).reduce(
          (acc, cur) => acc + cur.countTotal,
          0
        )
      } else {
        this.tableList = {}
        this.tableListChecked = 0
        this.tableListTotal = 0
      }
      console.log('this.tableList')
      console.log(this.tableList)
      if (renewSVG) {
        this.mountSvg = false
        this.$nextTick(() => {
          this.mountSvg = true
        })
      }
    },
    setFormSmallParts (parts) {
      if (Object.keys(parts).length < 4 || parts.back) {
        this.resetView()
      } else {
        this.$store.commit('inspectionDamageDialog/SET_PRINCIPAL_SVG', parts)
      }
    },
    async changeBridgeEvent (cb = () => {}) {
      if (!this.bridgePartId) return
      // --
      this.mountSvg = false
      this.brFlag = 1
      // --
      this.partCode = this.bridgePartArray.find(
        (item) => item.bridgePartId === this.bridgePartId
      ).name
      // --
      this.startLocation = this.bridgeOverview.startLocation
      this.endLocation = this.bridgeOverview.endLocation
      if (this.bridgeOverview.direction === 'ซ้ายทาง') {
        this.bridgeDirection = 'left'
      } else if (this.bridgeOverview.direction === 'ขวาทาง') {
        this.bridgeDirection = 'right'
      }
      // --
      const sideType = ['Box Culvert', 'Box Underpass']
      const isSideType = sideType.includes(
        this.getBridge[0].bridge_strurture_name
      )
      console.log('isSideType:', isSideType)
      if (isSideType) {
        this.bridgeViewArray = ['sideView']
        this.bridgeView = 'sideView'
      } else {
        this.bridgeViewArray = ['topView', 'bottomView', 'sideView']
        if (!this.bridgeView) {
          this.bridgeView = 'topView'
        } else if (this.bridgeView === 'crossSectionView') {
          this.bridgeView = 'bottomView'
        }
      }
      // --
      this.bridgeType = isSideType
        ? this.getBridge[0].bridge_strurture_name // ['Box Culvert', 'Box Underpass']
        : this.getBridge[0].type_name // Slab, or ....
      // --
      const bridgePartComponent = []
      this.bridgeSubPartComplete = []
      const segmentHead = isSideType ? 'WW' : 'A'
      if (!isSideType && !this.bridgeHasA1) {
        // not side type and not have a1
      } else {
        bridgePartComponent.push({
          segment: segmentHead,
          seq: 1,
          isCompleted: false
        })
      }
      if (this.bridgeView === 'topView') {
        this.bridgeSubPartComplete.push({
          segment: 'J',
          seq: 1,
          isCompleted: false
        })
      }
      // --
      const bridgeSegment = this.getBridge.bridge_segment
      console.log('bridgeSegment:', bridgeSegment)
      bridgeSegment.forEach((item, index) => {
        bridgePartComponent.push({
          segment: 'S',
          seq: item.seq || index + 1,
          isCompleted: false
        })
        if (this.bridgeView === 'topView') {
          this.bridgeSubPartComplete.push({
            segment: 'J',
            seq: index + 2,
            isCompleted: false
          })
        } else if (this.bridgeView === 'bottomView') {
          if (bridgeSegment[index + 1]) {
            this.bridgeSubPartComplete.push({
              segment: 'P',
              seq: index + 1,
              isCompleted: true
            })
          }
        }
      })
      if (!isSideType && !this.bridgeHasA2) {
        // not side type and not have a2
      } else {
        bridgePartComponent.push({
          segment: segmentHead,
          seq: 2,
          isCompleted: false
        })
      }
      // --
      console.log('bridgeSubPartComplete:', this.bridgeSubPartComplete)
      this.bridgePartComponent = bridgePartComponent
      await this.getIndeptSectorComplete(this.partCode)
      if (isSideType) {
        this.getIndepthComponentComplete().then(() => {})
      }
      this.svgStep = 0
      this.$nextTick(() => {
        this.mountSvg = true
        cb()
      })
    },
    resetView () {
      this.svgStep = 0
      this.$store.commit('inspectionDamageDialog/SET_PRINCIPAL_SVG', {})
      this.tableList = null
      setTimeout(this.changeBridgeEvent, 100)
    }
  },
  computed: {
    ...mapState('inspectionDamageDialog', {
      inspectionType: 'inspectionType',
      inspectionId: 'inspectionId',
      bridgeId: 'bridgeId'
    }),
    ...mapState('inspectionDamageDialog', {
      partsText: 'partsText',
      storeSectorId: 'sectorId',
      storeSectorNo: 'sectorNo',
      componentId: 'componentId',
      componentNo: 'componentNo'
    }),
    ...mapGetters('inspectionDamageDialog', {
      getBridge: 'getBridge',
      bridgePartArray: 'bridgePartArray'
    }),
    ...mapState('bridgeDetail', { bridgeOverview: 'bridgeOverview' }),
    ...mapGetters('bridgeDetail', {
      bridgeHasA1: 'bridgeHasA1',
      bridgeHasA2: 'bridgeHasA2'
    }),
    description () {
      if (this.partsText) {
        return this.partsText
      } else if (
        this.storeSectorId &&
        this.storeSectorNo &&
        this.componentId &&
        this.componentNo
      ) {
        if (this.componentId === 'WW') {
          return this.componentId + this.componentNo
        } else {
          return (
            this.storeSectorId +
            this.storeSectorNo +
            '-' +
            this.componentId +
            this.componentNo
          )
        }
      } else {
        return ''
      }
    },
    hasText () {
      return (
        String(this.inspectionType) === '3' &&
        (this.svgStep === 1 ||
          (this.getBridge &&
            ['Box Culvert', 'Box Underpass'].includes(
              this.getBridge[0].bridge_strurture_name
            )))
      )
    },
    bridgeView: {
      get () {
        return this.$store.state.inspectionDamageDialog.bridgeView
      },
      set (to) {
        this.$store.commit('inspectionDamageDialog/SET_BRIDGE_VIEW', to)
      }
    },
    svgStep: {
      get () {
        return this.$store.state.inspectionDamageDialog.svgStep
      },
      set (to) {
        this.$store.commit('inspectionDamageDialog/SVG_STEP', to)
      }
    },
    bridgePartId: {
      get () {
        return this.$store.state.inspectionDamageDialog.bridgePartId
      },
      set (to) {
        if (to) {
          this.$store.commit(
            'inspectionDamageDialog/SET_SELECTED_BRIDGE_PART',
            to
          )
        }
      }
    }
  },
  watch: {
    bridgePartId () {
      this.resetView()
    }
  }
}
</script>

<style scoped>
.bridgeSvgContainer {
  width: 100%;
}

::v-deep .v-data-table__wrapper {
  height: 100%;
}

tr.expander {
  cursor: pointer;
}

tr.expander:hover {
  text-decoration: underline;
}

::v-deep
  .iterate-table
  > .v-data-table__wrapper
  > table
  > tbody
  > tr:last-child
  > td {
  border-bottom: thin solid rgba(0, 0, 0, 0.12);
}

@media screen and (max-width: 600px) {
  ::v-deep .iterate-table > .v-data-table__wrapper > table > thead > tr > th {
    padding: 0 8px;
  }

  ::v-deep .iterate-table > .v-data-table__wrapper > table > tbody > tr > td {
    padding: 0 8px;
  }

  .dataContainer {
    width: 100%;
    height: calc(100% - 38px - 40px - 62px - 16px);
    display: block;
  }

  .bridgeSvgContainer {
    height: 100%;
  }

  .bridgeSvgContainer-hasText {
    height: 50%;
  }

  .textExplain {
    width: 100%;
  }
}

@media screen and (min-width: 600px) {
  .dataContainer {
    width: 100%;
    min-height: 250px;
    height: calc(100% - 38px - 40px - 62px - 16px);
    display: flex;
  }

  .bridgeSvgContainer-hasText {
    width: calc(100% - 375px);
  }

  .textExplain {
    width: 375px;
  }
}
</style>
