<template>
  <div class="pa-3 pb-1 myTableParent"
       style="height: 100%;">
    <div class="d-sm-flex justify-space-between">
      <div class="d-flex">
        <v-select
          v-model="selectedComponent"
          :items="selectionMode"
          class="mySelect"
          dense
          hide-details
          item-text="name"
          item-value="id"
          outlined
          @change="updateTable"
        />
        <span v-if="selectedComponent === -1 && overviewBhi" class="d-flex align-center ml-2" style="font-size: 0.875rem;">
          BHI = {{ Number(overviewBhi).toLocaleString(undefined, { maximumFractionDigits: 2 }) }}
        </span>
      </div>
      <div class="mt-2 mt-sm-0 text-right d-flex align-center text--secondary">
        <div class="dot-label green mr-1"/>
        ดี
        <div class="dot-label tableChip_yellow ml-2 mr-1"/>
        ปานกลาง
        <div class="dot-label orange ml-2 mr-1"/>
        บกพร่อง
        <div class="dot-label red ml-2 mr-1"/>
        วิกฤต
      </div>
    </div>
    <!-- <v-simple-table v-if="selectedComponent === null"
                    class="drop-shadow mt-3 myTable"
                    fixed-header>
      <template v-slot:default>
        <thead>
          <tr class="white">
            <th scope="col">
              รหัส
            </th>
            <th scope="col">
              ความเสียหาย
            </th>
            <th scope="col">
              ปริมาณ
            </th>
            <th scope="col">
              หน่วย
            </th>
            <th class="text-center"
                scope="col">
              สภาพความเสียหาย
            </th>
          </tr>
        </thead>
        <tbody>
          <tr v-if="aggregateItems.length === 0">
            <td class="text-center"
                colspan="5">
              ไม่มีข้อมูล
            </td>
          </tr>
          <template v-for="component in aggregateItems">
            <tr :key="component.component_id"
                class="white">
              <td class="font-weight-bold primary--text"
                  colspan="5"
                  style="font-size: 1rem;">
                {{ component.component_id + ' (' + component.component_name + ')' }}
              </td>
            </tr>
            <template v-for="sector in component.items">
              <template v-for="sectorNo in sector.items">
                <tr :key="sector.sector_id + sectorNo.sector_no + '-' + component.component_id"
                    class="primary-light">
                  <td class="font-weight-bold"
                      colspan="2">
                    {{ sector.sector_id + sectorNo.sector_no + '-' + component.component_id }}
                  </td>
                  <td>
                    {{ sectorNo.area_critical }}
                  </td>
                  <td>
                    {{ getUnitFromComponentId(component.component_id) }}
                  </td>
                  <td>
                    <div class="d-flex">
                      <div :data-value="sectorNo.area_critical_1"
                           class="tableChip tableChip_green">
                        {{ sectorNo.area_critical_1 }}
                      </div>
                      <div :data-value="sectorNo.area_critical_2"
                           class="tableChip tableChip_yellow">
                        {{ sectorNo.area_critical_2 }}
                      </div>
                      <div :data-value="sectorNo.area_critical_3"
                           class="tableChip tableChip_orange">
                        {{ sectorNo.area_critical_3 }}
                      </div>
                      <div :data-value="sectorNo.area_critical_4"
                           class="tableChip tableChip_red">
                        {{ sectorNo.area_critical_4 }}
                      </div>
                    </div>
                  </td>
                </tr>
                <tr v-for="(damage, index) in sectorNo.items"
                    :key="sector.sector_id + sectorNo.sector_no + '-' + component.component_id + '-' + damage.type.id + `(${index})`"
                    :class="{ 'white': index%2===0, 'theme-bg': index%2===1 }">
                  <td>
                    {{ damage.type.id }}
                  </td>
                  <td colspan="3">
                    {{ damage.type.name }}
                  </td>
                  <td>
                    <div class="d-flex">
                      <div :class="{ 'not-visible' : damage.score.condition !== 'ดี'}"
                           class="tableChip tableChip_green">
                        {{ damage.area_critical }}
                      </div>
                      <div :class="{ 'not-visible' : damage.score.condition !== 'ปานกลาง'}"
                           class="tableChip tableChip_yellow">
                        {{ damage.area_critical }}
                      </div>
                      <div :class="{ 'not-visible' : damage.score.condition !== 'แย่'}"
                           class="tableChip tableChip_orange">
                        {{ damage.area_critical }}
                      </div>
                      <div :class="{ 'not-visible' : damage.score.condition !== 'วิกฤต'}"
                           class="tableChip tableChip_red">
                        {{ damage.area_critical }}
                      </div>
                    </div>
                  </td>
                </tr>
              </template>
            </template>
          </template>
        </tbody>
      </template>
    </v-simple-table> -->
    <v-simple-table v-if="selectedComponent === -1"
                    class="drop-shadow mt-3 myTable"
                    fixed-header>
      <template v-slot:default>
        <thead>
          <tr class="white">
            <th scope="col">
              ชิ้นส่วนสะพาน
            </th>
            <th scope="col">
              ปริมาณ
            </th>
            <th scope="col">
              หน่วย
            </th>
            <th scope="col">
              สภาพความเสียหาย
            </th>
            <th scope="col" style="text-align: right;">
              EHI
            </th>
            <th scope="col">
              Weight
            </th>
          </tr>
        </thead>
        <tbody>
          <template v-for="component in overviewTable">
            <tr v-if="component.type !== 'group'" :key="component.name">
              <td>{{ component.name }}</td>
              <td>{{ textIsTonOrChin(component.unit.name) ? Number(component.sum_count).toLocaleString(undefined, { maximumFractionDigits: 2 }) : Number(component.sum).toLocaleString(undefined, { maximumFractionDigits: 2 }) }}</td>
              <td>{{ component.unit.name }}</td>
              <td>
                <div class="d-flex">
                  <div :data-value="getScoreUsingUnitName(component.scores[1], component.unit.name) || 0"
                       class="tableChip tableChip_green">
                    {{ getScoreUsingUnitName(component.scores[1], component.unit.name) || 0 }}
                  </div>
                  <div :data-value="getScoreUsingUnitName(component.scores[2], component.unit.name) || 0"
                       class="tableChip tableChip_yellow">
                    {{ getScoreUsingUnitName(component.scores[2], component.unit.name) || 0 }}
                  </div>
                  <div :data-value="getScoreUsingUnitName(component.scores[3], component.unit.name) || 0"
                       class="tableChip tableChip_orange">
                    {{ getScoreUsingUnitName(component.scores[3], component.unit.name) || 0 }}
                  </div>
                  <div :data-value="getScoreUsingUnitName(component.scores[4], component.unit.name) || 0"
                       class="tableChip tableChip_red">
                    {{ getScoreUsingUnitName(component.scores[4], component.unit.name) || 0 }}
                  </div>
                </div>
              </td>
              <td style="text-align: right;">
                {{ Number(component.ehi).toLocaleString(undefined, { maximumFractionDigits: 2 }) }}
              </td>
              <td style="text-align: right;">
                {{ Number(component.weight).toLocaleString(undefined, { maximumFractionDigits: 2 }) }}
              </td>
            </tr>
            <template v-else>
              <tr :key="component.name">
                <td colspan="4">
                  {{ component.name }}
                </td>
              </tr>
              <template v-for="subComponent in component.items">
                <tr v-if="subComponent.id"
                    :key="subComponent.name">
                  <td>
                    <ul>
                      <li style="margin-left: 12px;">
                        {{ subComponent.name }}
                      </li>
                    </ul>
                  </td>
                  <td>{{ textIsTonOrChin(subComponent.unit.name) ? Number(subComponent.sum_count).toLocaleString(undefined, { maximumFractionDigits: 2 }) : Number(subComponent.sum).toLocaleString(undefined, { maximumFractionDigits: 2 }) }}</td>
                  <td> {{ subComponent.unit.name }}</td>
                  <td>
                    <div class="d-flex">
                      <div :data-value="getScoreUsingUnitName(subComponent.scores[1], subComponent.unit.name) || 0"
                           class="tableChip tableChip_green">
                        {{ getScoreUsingUnitName(subComponent.scores[1], subComponent.unit.name) || 0 }}
                      </div>
                      <div :data-value="getScoreUsingUnitName(subComponent.scores[2], subComponent.unit.name) || 0"
                           class="tableChip tableChip_yellow">
                        {{ getScoreUsingUnitName(subComponent.scores[2], subComponent.unit.name) || 0 }}
                      </div>
                      <div :data-value="getScoreUsingUnitName(subComponent.scores[3], subComponent.unit.name) || 0"
                           class="tableChip tableChip_orange">
                        {{ getScoreUsingUnitName(subComponent.scores[3], subComponent.unit.name) || 0 }}
                      </div>
                      <div :data-value="getScoreUsingUnitName(subComponent.scores[4], subComponent.unit.name) || 0"
                           class="tableChip tableChip_red">
                        {{ getScoreUsingUnitName(subComponent.scores[4], subComponent.unit.name) || 0 }}
                      </div>
                    </div>
                  </td>
                  <td style="text-align: right;">
                    {{ Number(subComponent.ehi).toLocaleString(undefined, { maximumFractionDigits: 2 }) }}
                  </td>
                  <td style="text-align: right;">
                    {{ Number(subComponent.weight).toLocaleString(undefined, { maximumFractionDigits: 2 }) }}
                  </td>
                </tr>
              </template>
            </template>
          </template>
        </tbody>
      </template>
    </v-simple-table>
    <v-simple-table v-if="[1, 2, 3, 4].includes(parseInt(selectedComponent))"
                    class="drop-shadow mt-3 myTable"
                    fixed-header>
      <template v-slot:default>
        <colgroup>
          <col>
          <col style="min-width: 120px;">
          <col>
          <col>
          <col>
        </colgroup>
        <thead>
          <tr class="white">
            <th scope="col">
              รหัสความเสียหาย
            </th>
            <th scope="col">
              ปริมาณ
            </th>
            <th scope="col">
              หน่วย
            </th>
            <th class="text-center" scope="col">
              สภาพความเสียหาย
            </th>
          </tr>
        </thead>
        <tbody>
          <tr v-if="aggregateOption.length === 0">
            <td class="text-center" colspan="4">
              ไม่มีข้อมูล
            </td>
          </tr>
          <template v-for="component in aggregateOption">
            <tr :key="component.component_id"
                class="white">
              <td class="font-weight-bold primary--text"
                  colspan="4"
                  style="font-size: 1rem;">
                {{ component.component_id + ' (' + component.component_name + ')' }}
              </td>
            </tr>
            <template v-for="[sectorName, sectorValue] in Object.entries(component.items)">
              <template v-for="sectorNo in sectorValue">
                <tr :key="sectorName + sectorNo.sector_no + '-' + component.component_id"
                    class="primary-light"
                    style="position: sticky; top: 48px;">
                  <td class="font-weight-bold">
                    {{ sectorName + sectorNo.sector_no + '-' + component.component_id }}
                  </td>
                  <td>
                    {{ textIsTonOrChin(component.unit.name) ? sectorNo.sum_count : sectorNo.sum }}
                  </td>
                  <td>
                    {{ component.unit.name }}
                  </td>
                  <td>
                    <div class="d-flex">
                      <div :data-value="textIsTonOrChin(component.unit.name) ? sectorNo.count_1 : sectorNo.sum_1"
                           class="tableChip tableChip_green">
                        {{ parseFloat(textIsTonOrChin(component.unit.name) ? sectorNo.count_1 : sectorNo.sum_1).toLocaleString() }}
                      </div>
                      <div :data-value="textIsTonOrChin(component.unit.name) ? sectorNo.count_2 : sectorNo.sum_2"
                           class="tableChip tableChip_yellow">
                        {{ parseFloat(textIsTonOrChin(component.unit.name) ? sectorNo.count_2 : sectorNo.sum_2).toLocaleString() }}
                      </div>
                      <div :data-value="textIsTonOrChin(component.unit.name) ? sectorNo.count_3 : sectorNo.sum_3"
                           class="tableChip tableChip_orange">
                        {{ parseFloat(textIsTonOrChin(component.unit.name) ? sectorNo.count_3 : sectorNo.sum_3).toLocaleString() }}
                      </div>
                      <div :data-value="textIsTonOrChin(component.unit.name) ? sectorNo.count_4 : sectorNo.sum_4"
                           class="tableChip tableChip_red">
                        {{ parseFloat(textIsTonOrChin(component.unit.name) ? sectorNo.count_4 : sectorNo.sum_4).toLocaleString() }}
                      </div>
                    </div>
                  </td>
                </tr>
                <tr v-for="(componentNo, index) in sectorNo.items"
                    :key="sectorName + sectorNo.sector_no + '-' + component.component_id + componentNo.component_no + `(${index})`">
                  <td colspan="3">
                    [{{ componentNo.damage_indepth_group.id }}] {{ componentNo.damage_indepth_group.name }}
                  </td>
                  <td>
                    <div class="d-flex">
                      <div :class="{ 'not-visible' : componentNo.score.name !== 'ดี'}"
                           class="tableChip tableChip_green">
                        {{ parseFloat(textIsTonOrChin(component.unit.name) ? componentNo.sum_count : componentNo.sum_area_critical).toLocaleString() }}
                      </div>
                      <div :class="{ 'not-visible' : componentNo.score.name !== 'ปานกลาง'}"
                           class="tableChip tableChip_yellow">
                        {{ parseFloat(textIsTonOrChin(component.unit.name) ? componentNo.sum_count : componentNo.sum_area_critical).toLocaleString() }}
                      </div>
                      <div :class="{ 'not-visible' : componentNo.score.name !== 'บกพร่อง'}"
                           class="tableChip tableChip_orange">
                        {{ parseFloat(textIsTonOrChin(component.unit.name) ? componentNo.sum_count : componentNo.sum_area_critical).toLocaleString() }}
                      </div>
                      <div :class="{ 'not-visible' : componentNo.score.name !== 'วิกฤต'}"
                           class="tableChip tableChip_red">
                        {{ parseFloat(textIsTonOrChin(component.unit.name) ? componentNo.sum_count : componentNo.sum_area_critical).toLocaleString() }}
                      </div>
                    </div>
                  </td>
                </tr>
              </template>
            </template>
          </template>
        </tbody>
      </template>
    </v-simple-table>
    <div
      class="d-block d-sm-none"
      data-explain="padding reserve for plus btn"
      style="height: 60px;"/>
  </div>
</template>

<script>
import { mapGetters, mapState } from 'vuex'
import api from '@/assets/js/api'

export default {
  name: 'StepZeroAnyTable',
  data () {
    return {
      selectedComponent: -1,
      overviewTable: [],
      groupTable: [],
      overviewBhi: 0
    }
  },
  methods: {
    async updateTable (newComponentVal) {
      const value = this.selectedComponent
      this.$store.commit('inspectionDamageDialog/SET_LOADING', true)
      if (value === null) {
        // --
      } else if (value === -1) {
        const url = `${process.env.VUE_APP_WS_BASE}/get/inspection-indepth-summary-overview/${this.inspectionId}`
        const response = await api.get({ url })
        this.overviewBhi = response.data.data.bhi
        this.overviewTable = response.data.data.info
      } else {
        const url = `${process.env.VUE_APP_WS_BASE}/get/inspection-indepth-summary-group/${this.inspectionId}/${value}`
        const response = await api.get({ url })
        this.groupTable = response.data.data
      }
      this.$store.commit('inspectionDamageDialog/SET_LOADING', false)
      // sometimes vue does not detect selectedComponent change
      //   ,so we need to change like this
      this.selectedComponent = value
    },
    getScoreUsingUnitName (score, unitName) {
      let val
      if (unitName === 'ต้น' || unitName === 'ชิ้น') {
        val = score?.sum_count || 0
      } else {
        val = score?.sum || 0
      }
      val = Number(val)
      return val.toLocaleString(undefined, { maximumFractionDigits: 2 })
    },
    textIsTonOrChin (text) {
      return text === 'ต้น' || text === 'ชิ้น'
    }
  },
  computed: {
    ...mapState('inspectionDamageDialog', { othersSummary: 'othersSummary', inspectionId: 'inspectionId' }),
    ...mapState({ bridgeComponentIndepthGroup: 'bridgeComponentIndepthGroup' }),
    ...mapGetters({ getUnitFromComponentId: 'getUnitFromComponentId' }),
    selectionMode () {
      return [{ id: -1, name: 'สรุปความเสียหาย' }, ...this.bridgeComponentIndepthGroup]
    },
    aggregateItems () {
      const summary = JSON.parse(JSON.stringify(this.othersSummary || [])) || []
      summary.forEach(component => {
        component.items.forEach(sector => {
          sector.items.forEach(sectorNo => {
            sectorNo.area_critical_1 = sectorNo.items.filter(damage => damage.score.condition === 'ดี').reduce((acc, cur) => acc + cur.area_critical, 0)
            sectorNo.area_critical_2 = sectorNo.items.filter(damage => damage.score.condition === 'ปานกลาง').reduce((acc, cur) => acc + cur.area_critical, 0)
            sectorNo.area_critical_3 = sectorNo.items.filter(damage => damage.score.condition === 'บกพร่อง').reduce((acc, cur) => acc + cur.area_critical, 0)
            sectorNo.area_critical_4 = sectorNo.items.filter(damage => damage.score.condition === 'วิกฤต').reduce((acc, cur) => acc + cur.area_critical, 0)
            sectorNo.area_critical = sectorNo.area_critical_1 + sectorNo.area_critical_2 + sectorNo.area_critical_3 + sectorNo.area_critical_4
          })
        })
      })
      return summary
    },
    aggregateOption () {
      const summary = JSON.parse(JSON.stringify(this.groupTable)) || []
      summary.forEach(component => {
        Object.entries(component.items).forEach(([sectorName, sectorValue]) => {
          sectorValue.forEach(sectorNo => {
            sectorNo.sum_1 = sectorNo.items.filter(damage => damage.score.name === 'ดี').reduce((acc, cur) => acc + parseFloat(cur.sum_area_critical ?? 0), 0)
            sectorNo.sum_2 = sectorNo.items.filter(damage => damage.score.name === 'ปานกลาง').reduce((acc, cur) => acc + parseFloat(cur.sum_area_critical ?? 0), 0)
            sectorNo.sum_3 = sectorNo.items.filter(damage => damage.score.name === 'บกพร่อง').reduce((acc, cur) => acc + parseFloat(cur.sum_area_critical ?? 0), 0)
            sectorNo.sum_4 = sectorNo.items.filter(damage => damage.score.name === 'วิกฤต').reduce((acc, cur) => acc + parseFloat(cur.sum_area_critical ?? 0), 0)
            // --
            sectorNo.count_1 = sectorNo.items.filter(damage => damage.score.name === 'ดี').reduce((acc, cur) => acc + parseFloat(cur.sum_count ?? 0), 0)
            sectorNo.count_2 = sectorNo.items.filter(damage => damage.score.name === 'ปานกลาง').reduce((acc, cur) => acc + parseFloat(cur.sum_count ?? 0), 0)
            sectorNo.count_3 = sectorNo.items.filter(damage => damage.score.name === 'บกพร่อง').reduce((acc, cur) => acc + parseFloat(cur.sum_count ?? 0), 0)
            sectorNo.count_4 = sectorNo.items.filter(damage => damage.score.name === 'วิกฤต').reduce((acc, cur) => acc + parseFloat(cur.sum_count ?? 0), 0)
          })
        })
      })
      return summary
    }
  }
}
</script>

<style>
@media screen and (min-width: 600px) {
  .mySelect {
    max-width: 250px;
  }
}

.tableChip {
  padding: 0 8px;
  margin-right: 4px;
  color: white;
  border-radius: 5px;
  text-align: center;
  width: 25%;
  min-width: fit-content;
  white-space: nowrap;
  overflow: hidden;
}

.tableChip_green {
  background-color: #92c22d;
}

.tableChip_yellow {
  background-color: #FbCF31;
}

.tableChip_orange {
  background-color: #ff9800;
}

.tableChip_red {
  background-color: #F44336;
}

.tableChip_green[data-value="0"] {
  background-color: #92c22d19;
}

.tableChip_yellow[data-value="0"] {
  background-color: #FbCF3119;
}

.tableChip_orange[data-value="0"] {
  background-color: #ff980019;
}

.tableChip_red[data-value="0"] {
  background-color: #F4433619;
}

.dot-label {
  width: 8px;
  height: 8px;
  border-radius: 50%;
}

@media screen and (min-width: 600px) {
  .myTable {
    height: calc(100% - 52px);
    overflow-y: auto;
  }
}

@media screen and (max-width: 600px) {
  .myTableParent {
    overflow-y: auto;
  }
}

.not-visible {
  visibility: hidden;
}
</style>

<style scoped>
::v-deep .myTable > .v-data-table__wrapper > table > thead > tr > th {
  font-size: 0.875rem;
}

::v-deep .v-select__selections {
  line-height: unset;
}

::v-deep td {
  border: unset !important;
}

::v-deep .v-data-table__wrapper {
  height: 100%;
}

::v-deep .v-data-table__wrapper table {
  position: relative;
}

::v-deep .v-data-table__wrapper > table > thead > tr {
  position: sticky;
  top: 0;
}
</style>
