<template>
  <div class="pa-3 pb-0">
    <div>
      <div class="d-flex justify-space-between">
        <span>
          รหัสชิ้นส่วน
          <span class="red--text">*</span>
        </span>
        <a v-if="can_add_inspection"
           class="blue--text underline-hover"
           @click="back">
          แก้ไข
        </a>
      </div>
      <div class="primary-light py-2 px-3">
        {{
          (getBridge ? getBridge.name + '-' : '') +
          ((componentId === 'WW') ? componentId + componentNo : sectorId + sectorNo + '-' + componentId + componentNo)
        }}
      </div>
    </div>
    <div v-if="String(inspectionType) !== '2' && customElementNoWeight.length > 0"
         class="mt-3">
      <span>Element No.</span>
      <span class="red--text">
        *
      </span>
      <v-autocomplete
        v-model="elementNoWeight"
        :items="customElementNoWeight"
        class="mt-1"
        dense
        hide-details
        item-text="text"
        item-value="id"
        outlined/>
    </div>
    <div v-if="requiredMaterialWithName.length > 0" class="mt-3">
      <span>ชนิดวัสดุ</span>
      <span class="red--text">
        *
      </span>
      <v-select
        v-model="materialId"
        :items="requiredMaterialWithName"
        :rules="[rules.required]"
        class="mt-1"
        dense
        hide-details
        item-text="name"
        item-value="id"
        outlined/>
    </div>
    <step-two-dimension
      v-if="String(inspectionType) !== '2'"
      class="pa-3 mt-3"
      style="border: thin solid #DCDCDC;"/>
    <step-two-damage-area
      class="pa-3 mt-3"
      style="border: thin solid #ffd653; background: #fffaea;"/>
  </div>
</template>

<script>
import { mapGetters, mapState } from 'vuex'
import StepTwoDimension from '@/components/inspection/InspectionDialog/StepForm/StepTwoDimension'
import StepTwoDamageArea from '@/components/inspection/InspectionDialog/StepForm/StepTwoDamageArea'

export default {
  name: 'StepTwo',
  components: { StepTwoDamageArea, StepTwoDimension },
  data: () => ({
    rules: {
      required: (value) => !!value || 'ต้องการ'
    }
  }),
  methods: {
    back () {
      this.$store.commit('inspectionDamageDialog/SET_STEP', 1)
      this.$store.commit('inspectionDamageDialog/SET_DAMAGE_TYPE_ID', '')
      this.$store.commit('inspectionDamageDialog/RESET_DIMENSION')
      const { sectorId: sectorID, sectorNo, componentId: componentID, componentNo } = this
      const payload = { sectorID, sectorNo, componentID, componentNo, partsText: false }
      this.$store.commit('inspectionDamageDialog/SET_PRINCIPAL_SVG', payload)
      this.$nextTick(() => {
        this.$nextTick(() => {
          // wait two tick for stepOneComponent in vuex
          setTimeout(() => {
            // changeBridgeEvent รับ cb function เพื่อตรงนี้เลย
            this.stepOneComponent.changeBridgeEvent(() => {
              // simulate click selected component
              // ตรงนี้อาจจะงงๆ หน่อย พอดี setFormParts เขียนก่อนจะปรับชื่อตัวแปร
              this.stepOneComponent.setFormParts({ sectorID, sectorNo, componentID: sectorID, componentNo: sectorNo })
            })
          }, 100)
        })
      })
    }
  },
  computed: {
    ...mapState('inspectionDamageDialog', { stepOneComponent: 'stepOneComponent' }),
    ...mapState('inspectionDamageDialog', { inspectionType: 'inspectionType', elementNoWeightArray: 'elementNoWeightArray' }),
    ...mapGetters('inspectionDamageDialog', { requiredMaterialWithName: 'requiredMaterialWithName', getBridge: 'getBridge' }),
    ...mapState('inspectionDamageDialog', { sectorId: 'sectorId', sectorNo: 'sectorNo', componentId: 'componentId', componentNo: 'componentNo' }),
    ...mapGetters('bridgeDetail', { canEdit_permission: 'canEdit_permission' }),
    ...mapGetters({ user_can_add_inspection: 'can_add_inspection' }),
    can_add_inspection () {
      return this.user_can_add_inspection && this.canEdit_permission
    },
    elementNoWeight: {
      get () {
        return this.$store.state.inspectionDamageDialog.elementNoWeight
      },
      set (to) {
        this.$store.commit('inspectionDamageDialog/SET_ELEMENT_NO_WEIGHT', to)
      }
    },
    customElementNoWeight () {
      return this.elementNoWeightArray.map(item => ({ ...item, text: `${item.id}: ${item.name}` }))
    },
    materialId: {
      get () {
        return this.$store.state.inspectionDamageDialog.materialId
      },
      set (to) {
        this.$store.commit('inspectionDamageDialog/SET_SELECTED_MATERIAL', to)
      }
    }
  },
  watch: {
    materialId (to, from) {
      if (parseInt(this.inspectionType) === 2) {
        // if fetch on all inspectionType, this function will call twice
        //   1. Here
        //   2. StepTwoDimension.vue at watch:damageTypeFetchWatcher
        this.$store.dispatch('inspectionDamageDialog/fetchDamageMapping', { component_id: this.componentId })
      }
    },
    elementNoWeight (to, from) {
      if (['205-1', '205-2'].includes(to)) {
        const [, componentPoleId] = to.split('-')
        this.$store.commit('inspectionDamageDialog/SET_SELECTED_POLE', componentPoleId)
      }
    }
  }
}
</script>
